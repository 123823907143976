<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Bory Promenáda</h1>
          <p>Vďaka svojej tesnej blízkosti k Bory Mall je tretia etapa charakteristická výbornou občianskou vybavenosťou. Tá prináša pešiu vzdialenosť k obchodom, do kina, fitnes centra či k 25 m plaveckému bazénu.</p>
          <b-button to="/nasa-stvrt" class="d-block d-md-inline-block" squared variant="primary">O bývaní na Boroch</b-button>
<!--          <b-button class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0" squared variant="light">Stiahnuť brožúru</b-button>-->
        </div>
      </div>
    </div>

    <section class="district p-md-3 pt-3 pt-md-5">
      <div class="d-flex flex-wrap align-items-start top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">Mesto tak, ako ho poznáte</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">Bory kombinujú aktívne rodinné bývanie s bohatým verejným priestorom, kompletnou vybavenosťou, množstvom obchodov a pohodlnou dopravou do centra.</p>
        </div>
      </div>
      <div id="mapbox">
        <B3Map/>
      </div>
    </section>

    <section id="tabulka">
      <FlatsTable :stages="['bory3']" :showStageAndDate="false" :show-vr="false"></FlatsTable>
    </section>

    <section class="schedule p-3 p-md-5">
      <h2 class="mb-5">Harmonogram výstavby</h2>
      <div class="d-flex flex-wrap">
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3>Začiatok<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2020</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3>Žiadosť<br> o kolaudáciu</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2022</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3>Kolaudačný<br> proces</h3>
          <div class="line fill"></div>
          <p class="date">3. - 4. kvartál 2022</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3>Odovzdávanie<br> bytov</h3>
          <div class="line fill"></div>
          <p class="date">1. - 3. kvartál 2023</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kluce-byvanie.svg">
          </div>
          <h3>Vaše<br> bývanie</h3>
          <div class="line fill transparent"></div>
        </div>
      </div>
    </section>

    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 p-0 px-md-5 py-3">
            <h2>Buďte pri tom,<br> čo je dostupné</h2>
            <p>Rovnako ako v 1. a 2. etape aj túto vystihujú veľkorysé zelené plochy o rozlohe až 8 500 m<sup>2</sup>. Obyvateľov potešia kilometre cyklotrás, multifunkčné ihriská a blízkosť najmodernejšej nemocnice v strednej Európe. Rodiny navyše ocenia škôlku v pešej blízkosti. Vďaka blízkosti k diaľnici prináša lokalita Bory výbornú dostupnosť.</p>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-1">
              <h3>8 500 m<sup>2</sup><br> zelene</h3>
              <p>Centrálna časť je bezpečná pre obyvateľov i malé deti vďaka oddeleniu automobilovej dopravy umiestnenej len po obvode novej štvrte.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>488<br> parkovacích miest</h3>
              <p>Dostupných bude celkovo 320 vonkajších a 168 vnútorných parkovacích miest a samozrejme aj  elektro stanice.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>3<br> detské ihriská</h3>
              <p>Pre najmladších obyvateľov staviame detské ihriská, ktoré sú v každej etape projektu nevyhnutnosťou a tiež novú škôlku.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-bottom p-md-3 pl-md-5">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 py-4">
          <h2 class="mb-4">Domov, ktorý zažiari</h2>
          <p class="mb-3">Vďaka širokej ponuke štandardu si každý obyvateľ vyberie podľa svojich predstáv. <br>V snahe zabezpečiť vášmu bývaniu dlhú životnosť sme pre vás vybrali kvalitné materiály dostupné v rôznych farebných prevedeniach.</p>
<!--          <b-button squared variant="primary">Stiahnuť katalóg štandardov</b-button>-->
        </div>
        <div class="col-md-6 pb-3 pb-md-0 pr-md-0">
          <div class="right-img"></div>
        </div>
      </div>
    </section>

    <section class="about-bottom-2">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 bg"></div>
        <div class="col-md-6 signature-section">
          <p class="mb-4 text-italic font-weight-bolder">„Tretia etapa projektu ponúka štýlové a moderné bývanie v ôsmich bytových domoch, obkolesených množstvom zelene a kvalitným verejným priestorom s ústredným lineárnym parkom, ktorého súčasťou sú detské ihriská a fitnes plocha.“</p>
          <p class="name">Sadovsky&Architects</p>
          <p class="name">Mateja Vonkomerová a Silvia Rosíková, Oliver Sadovský</p>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import FlatsTable from "@/components/FlatsTable";
import B3Map from "@/components/maps/B3Map";
import ScrollTo from "@/plugins/scrollTo.util";
import ContactForm from "@/components/ContactForm";

export default {
  name: "Bory3",
  components: {ContactForm, B3Map, FlatsTable},
  mounted () {
    ScrollTo.hash(this,  () => window.scrollTo(0, 0))
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.about-bottom-2
  .bg
    background: url(../assets/images/bory3/bottom-image.jpg) center/cover no-repeat
.about-bottom
  .right-img
    background: url(../assets/images/bory3/bory3-domov.jpg) center/cover no-repeat

.schedule
  background: var(--textBright)
  .phase
    @media (max-width: $md)
      border-left: 2px solid var(--textDark)
      padding-left: 15px !important
      display: flex
      flex-direction: column
      padding-bottom: 20px !important
      &.fill
        border-color: var(--blue)
        &:before
          background: var(--blue)
      &.fill-half
        &:before
          background: var(--blue)
        &:after
          content: ""
          width: 2px
          height: 60%
          background: var(--blue)
          position: absolute
          left: -2px
          top: 0
      &:last-child
        border-color: transparent
      &:before
        content: ""
        width: 12px
        height: 12px
        background: var(--textDark)
        border-radius: 50%
        position: absolute
        left: -7px
        top: 0
        margin-top: 0
  h3
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.5px
    @media (max-width: $md)
      order: 3
  p.date
    font-size: 16px
    line-height: 20px
    letter-spacing: -0.5px
    color: var(--blue)
    font-weight: 500
    @media (max-width: $md)
      order: 1
      margin-top: -3px
  .icon
    height: 40px
    margin-bottom: 10px
    @media (max-width: $md)
      order: 2
  .line
    margin: 20px 0
    height: 2px
    background: var(--textDark)
    position: relative
    @media (max-width: $md)
      display: none
    &:before
      content: ""
      width: 12px
      height: 12px
      background: var(--textDark)
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      margin-top: -5px
    &.fill
      background: var(--blue)
      &:before
        background: var(--blue)
    &.fill-half, &.fill-min
      &:before
        background: var(--blue)
    &.fill-min
      &:after
        width: 15%
    &.transparent
      background: transparent

.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .bg-img
    background: url("../assets/images/Bory_Promenada-3.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 320px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory3/bory3.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/Bory_Promenada-1.jpg") bottom/cover no-repeat
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory3/bory3-ihriska.jpg") center/cover no-repeat
    background-position: -350px center
    @media (max-width: $md)
      background-position: center
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/Bory_BH3-31.jpg") top/cover no-repeat
  p
    margin-bottom: 30px
    max-width: 770px

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>
